import React, { useState } from "react";
import BoxTypo from "../components/BoxTypo";
import { Box, Grid, Button } from "@material-ui/core";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/Footer_nomal";
import mizutama from "../../static/images/mizutama.png";
import "../css/base.css";
const Main = () => {
  const data = useStaticQuery(graphql`
    query {
      paper1: file(relativePath: { eq: "paper3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paper2: file(relativePath: { eq: "paper4.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box>
        <Box width="100%" bgcolor="#fcfcfc">
          <Head />
          <Box
            width="100%"
            m={"auto"}
            pb={30}
            style={{
              backgroundImage: `url(${mizutama})`,
              backgroundRepeat: "no-repeat",
              objectFit: "fit",
            }}
          >
            <Box width={"100%"} m={"auto"}>
              <Img fluid={data.paper2.childImageSharp.fluid} />
              <Img fluid={data.paper1.childImageSharp.fluid} className="" />
            </Box>
            <Link className="bottom" to="/" width="150px">
              <BoxTypo
                Tsukubrdgothic
                fs={["18px"]}
                ls={["2px"]}
                lh={["200%"]}
                mt={[5]}
                mb={[0]}
                textAlign="center"
              >
                ←TOPへ戻る
              </BoxTypo>
            </Link>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};
export default Main;
